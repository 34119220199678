<template>
  <div class="lg:tw-flex wrapper lg:tw-justify-between tw-flex-col tw-px-6 lg:tw-w-7/12">
    <div class="py-9 tw-hidden lg:tw-block">
      <div  id="previous" @click="$router.push('/edit-profile/description')" class="tw-flex">
        <ChevronLeftIcon/>
        <p class="tw-font-bold tw-text-base">Back</p>
      </div>
    </div>
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form id="editProfile" @submit.prevent="handleSubmit(save)">

        <div class="tw-p-2 lg:tw-hidden">
          <vue-feather @click="back" id="chevron" type="chevron-left" style="color: #475661"/>
        </div>

        <div class="tw-mt-7 tw-flex tw-justify-between">
          <p class="edit">Edit Profile</p>
          <BaseButton type="submit" :loading="loading" id="save" button-text="Save"/>
        </div>

        <div class="lg:tw-flex lg:tw-justify-between">
          <!--     col 1  -->
          <div class="lg:tw-w-5/12">
            <div class="my-4">
              <div class="mb-1 tw-flex">
                <small class="">Company Logo </small>
              </div>
              <v-card :loading="loadingImage" flat v-model="showDragDiv">
                <div
                    v-show="file === ''"
                    :style="{ 'background-color': color }"
                    class="drag-div tw-bg-enumBlue5"
                    @dragleave="dragleave"
                    @dragover="dragover"
                    @drop="drop"
                    id="drop"
                >
                  <input  id="assetsFieldHandle"
                          ref="file"
                          accept="image/*"
                          class="w-px h-px opacity-0 overflow-hidden absolute"
                          name="fields[assetsFieldHandle][]"
                          style="display: none"
                          type="file"
                          @change="uploadToCloudinary()"


                  />
                  <label class="d-flex align-center justify-center upload-label flex-column cursor-pointer"
                         for="assetsFieldHandle">
                    <vue-feather style="color:#475661;margin-top: 10px" type="upload"></vue-feather>
<!--                    <UploadIcon class="tw-text-enumGrey2"/>-->
                    <span class="drag-drop mx-auto">
                    <p class="font-weight-bold tw-text-enumGrey2 my-2">Drag and drop or <span class="choose__file">choose file</span></p>
                    <p class="size__instruction tw-text-enumGrey3 d-block ">240x346 px Recommended, max size 24MB </p>
                  </span>
                  </label>
                </div>
              </v-card>
              <div v-if="!social.company_logo && loadingImage" class="file" style="cursor: pointer">
                <div class="d-flex align-center justify-center pt-3 px-3">
                  <span>Loading...</span>
                </div>
              </div>
              <div v-if="companyLogoUrl" class="" style="cursor: pointer">
                <div class="tw-flex align-center">
                  <div class="tw-flex align-center">
                    <v-img
                        :src="companyLogoUrl"
                        class="round-image pr-2"
                        transition="fade"
                        height="118"
                        width="150"
                        contain

                    />
                    <div class="tw-flex tw-flex-col">
                      <span class="tw-pl-3" v-if="file.name">{{ shortenedTitle(file.name) }}</span>
<!--                      <p class="tw-pl-3 fontSize" v-if="file.size">Size: {{ getSize(file.size) }}</p>-->
                      <span class="image__actions d-flex align-end " :class="{ 'bg-transparent': social.company_logo }">
                        <span class="tw-flex lg:tw-flex-row tw-flex-col  pl-3" v-if="social.company_logo">
                          <v-btn
                              class="mr-3 tw-mb-5 lg:tw-mb-0"
                              depressed
                              @click.prevent="openExplorer"
                          >
                            Replace
                          </v-btn>
<!--                          <v-btn class="" depressed @click.prevent="remove">Delete</v-btn>-->
                        </span>
                      </span>

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <p class="infor">Company Info</p>
            <div class="width">
              <ValidationProvider
                  name="website"
                  :rules="{
                regex: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
                }"
                  v-slot="{ errors }"
              >
                <small>Website</small>
                <v-text-field
                    hide-details
                    label="https://"
                    v-model="social.website"
                    solo
                    type="text"
                    id="website">
                </v-text-field>
                <span v-show="errors" class="err">{{
                    errors[0]
                  }}{{
                    errors[0] === "The website field format is invalid" ? ", should begin with https://" : ""
                  }} </span>
              </ValidationProvider>
            </div>

            <div class="width">
              <small>Company Size</small>
              <v-select
                  hide-details
                  :items="items"
                  label="Select your company size"
                  v-model="social.company_size"
                  solo
                  id="size">
              </v-select>
            </div>
            <div class="width">
              <small>Industry</small>
              <v-combobox
                  hide-details
                  :items="combo"
                  label="Select Industry"
                  v-model="social.industry"
                  solo
                  id="industry">
              </v-combobox>
            </div>

          </div>
          <!--      col 2 -->

          <div class="lg:tw-w-5/12">
            <div class="mb-4 width">
              <div class="mb-1">
                <small>Location</small>
              </div>
              <v-combobox id="location" hide-details solo v-model="social.location" :items="countries"
                          label="Select the location">
              </v-combobox>
            </div>
            <p class="infor">Social Links</p>
            <div class="width">
              <ValidationProvider
                  name="linkedin"
                  :rules="{
                regex: /^https[A-Z,a-z,0-9-_/:.]*/,
                }"
                  v-slot="{ errors }">
                <small>LinkedIn</small>
                <v-text-field
                    hide-details
                    solo
                    placeholder="https://www.linkedin.com/in"
                    v-model="social.linkedin"
                    class="width=360px"
                    id="linkedIn">
                </v-text-field>
                <span v-show="errors" class="err">{{
                    errors[0]
                  }}{{
                    errors[0] === "The linkedin field format is invalid" ? ", should begin with https" : ""
                  }} </span>
              </ValidationProvider>
            </div>
            <div class="width">
              <ValidationProvider
                  name="facebook"
                  :rules="{
                regex: /^https[A-Z,a-z,0-9-_/:.]*/,
                }"
                  v-slot="{ errors }">
                <small>Facebook</small>
                <v-text-field
                    hide-details
                    placeholder="https://facebook.com/"
                    solo
                    v-model="social.facebook"
                    class="width=360px"
                    id="facebook">
                </v-text-field>
                <span v-show="errors" class="err">{{
                    errors[0]
                  }}{{
                    errors[0] === "The facebook field format is invalid" ? ", should begin with https" : ""
                  }} </span>
              </ValidationProvider>
            </div>
            <div class="width">
              <ValidationProvider
                  name="instagram"
                  :rules="{
                regex: /^https[A-Z,a-z,0-9-_/:.]*/,
                }"
                  v-slot="{ errors }">
                <small>Instagram</small>
                <v-text-field
                    hide-details
                    placeholder="https://instagram.com/"
                    solo
                    v-model="social.instagram"
                    class="width=360px"
                    id="insta">
                </v-text-field>
                <span v-show="errors" class="err">{{
                    errors[0]
                  }}{{
                    errors[0] === "The instagram field format is invalid" ? ", should begin with https" : ""
                  }} </span>
              </ValidationProvider>
            </div>
            <div class="width">
              <ValidationProvider
                  name="twitter"
                  :rules="{
                regex: /^https[A-Z,a-z,0-9-_/:.]*/,
                }"
                  v-slot="{ errors }">
                <small>Twitter</small>
                <v-text-field
                    hide-details
                    placeholder="https://twitter.com/"
                    class="width=360px"
                    v-model="social.twitter"
                    solo
                    id="twitter">
                </v-text-field>
                <span v-show="errors" class="err">{{
                    errors[0]
                  }}{{ errors[0] === "The twitter field format is invalid" ? ", should begin with https" : "" }} </span>
              </ValidationProvider>
            </div>
          </div>
        </div>

      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>


import BaseButton from "@/components/UIComponents/BaseButton";
import {ChevronLeftIcon} from 'vue-feather-icons'
import {mapGetters} from "vuex";
import getSiteName from "@/services/utils";
import {getCompanyProfile, getCriteriaCountries} from "@/services/api/APIService";
import {viewFile} from "@/services/storage/aws";
import {uploadMediaFile} from "@/services/utils/fileUploader";

export default {
  name: "EditCompanySocialAccount",
  components: {BaseButton, ChevronLeftIcon},
  data() {
    return {
      showDragDiv: true,
      logo: "",
      file: "",
      color: "",
      loading: false,
      countries: ["Lagos, Nigeria"],
      loadingImage: false,
      items: [
        "1 - 5",
        "6 - 10",
        "11 - 50",
        "51 - 200",
        "201 - 500",
        "501 - 1000",
        "1001 - 5000",
        "5001 - 10000",
        "10000+"
      ],
      combo: ['Gaming', 'Programming', 'IT', 'Design'],
      social: {},
      companyLogoUrl: ''
    }
  },

  computed: {
    ...mapGetters('editCompany', ['getEditCompanyProfile', 'getCompanyProfile']),
  },

  created() {
    let siteName = getSiteName()
    getCompanyProfile(siteName).then(res => {
      viewFile({url: res.data.company_logo, context: this, property: 'companyLogoUrl'})
      viewFile({url: res.data.cover_image, context: res.data, property: 'cover_image'})
      this.social = res.data
      if (this.social.company_logo) this.file = this.social.company_logo
    })
        .catch(err => {
          console.log(err)
        })

    this.getAllCountries()
  },

  methods: {
    getAllCountries() {
      getCriteriaCountries().then(res => {
        res.data.data.map((country) => {
          country.states.map((state) => {
            this.countries.push(state.name + ", "  + country.name)
          })
        })
      })
          .catch(err => {
            console.log(err.response.data.detail)
          })
    },
    getSize(size) {
      if (!size) return "";
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (
          (Math.ceil(size / Math.pow(1024, i)).toFixed(2) * 1) +
          " " +
          ["B", "kB", "MB", "GB", "TB"][i]
      );
    },
    async handleImage(file) {
      const { key: company_logo_url, name: company_logo, size: company_logo_file_size } = await uploadMediaFile(file, 'company/company_logo')
      console.log(company_logo_url)
      let companyLogo = {company_logo_url, company_logo, company_logo_file_size};
      viewFile({ url: company_logo_url, context: this, property: 'companyLogoUrl' })
      console.log(companyLogo, 'Im here');
      this.social.company_logo = company_logo_url
    },

    openExplorer() {
      this.$refs.file.click();
    },

    async uploadToCloudinary() {
      this.loadingImage = true
      this.file = this.$refs.file.files[0];
      const exts = ['png', 'jpeg', 'jpg', 'tiff']
      let splitFile = this.file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      if (!exts.includes(fileExtension.toLowerCase())) {
        this.$displaySnackbar({message: 'Only accepts Image files', success: false})
        this.loadingImage = false
        this.file = "";
        return
      }
      try {

        await this.handleImage(this.file);
        this.loadingImage = false
      } catch (e) {
        this.loadingImage = false;

      }
    },

    back() {
      this.$router.push({name: 'RecruiterDashboard'})
    },

    populateData(companyProfile) {
      if (companyProfile) {
        this.social = companyProfile
      }
    },

    save() {
      let data = {
        data: this.social,
        site_name: getSiteName(),
        // company_logo: this.social.company_logo
      }
      console.log(this.company_logo_url)
      this.loading = true
      this.$store.dispatch('editCompany/editCompanyProfile', data)
          .then(res => {
            console.log(res)
            this.$displaySnackbar({
              message: 'Changes Saved',
              success: true
            })
            this.loading = false
            this.$router.push({name: 'EditCompanyProfileView'})
          })
          .catch(error => {
            console.log(error)
            this.$displaySnackbar({
              message: 'An error occurred, please try again',
              success: false
            })
          })
          .finally(() => this.loading = false)

    },

    shortenedTitle(file_name) {
      const shortenText = (text, length) => {
        if (text.length <= length) return text
        return text.substring(0, length) + '...'
      }
      return shortenText(file_name, 20)
    },
    async onChange() {
      await this.uploadToCloudinary()
      this.showDragDiv = false;
    },
    dragover(event) {
      event.preventDefault();
      this.color = "#F6F6F6";
    },
    dragleave() {
      this.color = "#FBFDFE";
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      this.color = "#FBFDFE";
    },
    remove() {
      this.file = "";
      this.social.company_logo_url = ''
      this.showDragDiv = true;
    }
  },
}
</script>

<style scoped>
.wrapper {
  padding-top: 75px;
  margin: auto;
}

.edit {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1E323F;
}

.drag-div {
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 10px;
  height: 118px;
}

.drag-drop {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.file {
  border-radius: 8px;
  width: 280px;
  height: 50px;
  border: 1px solid #eaeaea;
  margin-bottom: 28px;
}

.upload-label {
  height: 100%;
  cursor: pointer;
}

.choose__file {
  color: #008EEF;
  font-family: "DM Sans", serif;
}

.size__instruction {
  font-size: 11px;
}

p {
  font-family: "DM Sans", serif;
}

.infor {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #9CABB5;
  margin-top: 20px;
}

.width {
  margin-bottom: 25px;
}

.logo {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #001343;
}

.err {
  font-family: "DM Sans", serif;
  color: red;
  font-size: 13px;
}
</style>
