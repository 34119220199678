<template>
  <div>
      <Header>
        <template #right-header-component>
          <ProfileDropDown/>
        </template>
      </Header>
      <div>
        <EditCompanySocialAccount/>
      </div>
  </div>
</template>
<script>

import EditCompanySocialAccount from "@/components/dashboard/components/EditCompanySocialAccount";
import Header from "@/components/onboarding/reuseables/Header";
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
export default {
  name: "EditCompanySocialView",
  components: {EditCompanySocialAccount, Header, ProfileDropDown}
}
</script>

<style scoped>

</style>
